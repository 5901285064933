@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600;700&display=swap');

:root {
  --primary-color: #6c5ce7;
  --secondary-color: #a29bfe;
  --background-color: #f8f9fa;
  --text-color: #2d3436;
  --accent-color: #fd79a8;
  --success-color: #00b894;
  --error-color: #d63031;
  --card-bg-color: #ffffff;
}

the .dark-mode {
  --primary-color: #a29bfe;
  --secondary-color: #6c5ce7;
  --background-color: #2d3436;
  --text-color: #f8f9fa;
  --accent-color: #fd79a8;
  --success-color: #00b894;
  --error-color: #ff7675;
  --card-bg-color: #34495e;
}

* {
  box-sizing: border-box;
  margin-top: 2px;
  padding: 0;
}

body {
  font-family: 'Poppins', sans-serif;
  background-color: var(--background-color);
  color: var(--text-color);
  line-height: 1.6;
  margin: 0;
  padding: 0;
}

#root {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  overflow-x: hidden;
  position: relative;
}

.app-container {
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  padding: 0 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 1;
}

.home {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.home-content {
  width: 100%;
  box-sizing: border-box;
  margin: 0 auto;
  text-align: center;
}

@media (max-width: 1240px) {
  .home {
    max-width: 100%;
    padding: 0 20px;
  }
}

dynamic-banner,
.presale-section-wrapper {
  width: 100%;
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  left: 50%;
  transform: translateX(-50%);
  box-sizing: border-box;
}

dynamic-banner .banner-content,
.presale-section {
  width: 100%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
}

.home > * {
  width: 100%;
  max-width: 1200px;
  margin-bottom: 2rem;
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
}


@media (max-width: 1240px) {
  .app-container,
  .dynamic-banner .banner-content,
  .presale-section {
    padding: 1rem 20px;
  }
}

@media (max-width: 768px) {
  .app-container {
    padding: 0 15px;
  }
}


.container {
  width: 100%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 20px;
  box-sizing: border-box;
}

@media (max-width: 768px) {
  .container {
    padding: 0 15px;
  }
}

/* Header Styles */


/* Button Styles */
.btn {
  display: inline-block;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  font-weight: 500;
  text-decoration: none;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.1s ease;
  min-height: 44px;
}

.btn-primary {
  background-color: var(--primary-color);
  color: #ffffff;
}

.btn-primary:hover {
  background-color: #5f4dd0;
  transform: translateY(-2px);
}

.btn-secondary {
  background-color: var(--secondary-color);
  color: #ffffff;
}

.btn-secondary:hover {
  background-color: #8f84fe;
  transform: translateY(-2px);
}
/* Dark mode adjustments */
.dark-mode .global-header {
  background-color: #1a1a1a;
}

dark-mode .nav-menu {
  background-color: #2d3436;
}

dark-mode .menu-toggle {
  color: var(--secondary-color);
}

dark-mode .menu-toggle:hover {
  color: var(--primary-color);
}

/* Improved responsive design */
.dark-mode .side-menu {
  background-color: #2d3436;
}
  .tracks-grid {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }

/* Button Styles */
.btn {
  display: inline-block;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  font-weight: 500;
  text-decoration: none;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.1s ease;
  min-height: 44px;
}

.btn-primary {
  background-color: var(--primary-color);
  color: #ffffff;
}

.btn-primary:hover {
  background-color: #5f4dd0;
  transform: translateY(-2px);
}

.btn-secondary {
  background-color: var(--secondary-color);
  color: #ffffff;
}

.btn-secondary:hover {
  background-color: #8f84fe;
  transform: translateY(-2px);
}

/* Form Styles */
.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: 500;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1rem;
}

/* Card Styles */
.card {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-bottom: 20px;
}

/* Tracks Section Styles */

.page-title {
  font-size: 2.5rem;
  color: var(--primary-color);
  text-align: center;
  margin-bottom: 2rem;
}

/* Add these styles to your existing CSS file */

/* Artist Selection Styles */
.form-group select {
  width: 100%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 16px;
  line-height: 1.5;
  transition: border-color 0.3s ease;
  background-color: var(--background-color);
  color: var(--text-color);
}

.form-group select:focus {
  border-color: var(--primary-color);
  outline: none;
}

.create-artist-form {
  margin-top: 20px;
  padding: 20px;
  background-color: rgba(var(--primary-color-rgb), 0.1);
  border-radius: 8px;
}

.create-artist-form .form-group {
  margin-bottom: 15px;
}

.create-artist-form input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

.btn-secondary {
  background-color: var(--secondary-color);
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btn-secondary:hover {
  background-color: var(--primary-color);
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .create-artist-form {
    padding: 15px;
  }
}


.track-listing-page {
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
}

.latest-tracks-title {
  font-size: 2.5rem;
  font-weight: 700;
  text-align: center;
  margin-bottom: 2rem;
  color: #000000; /* Changed from #ffffff to #000000 */
  text-transform: uppercase;
  letter-spacing: 2px;
  position: relative;
}

.latest-tracks-title::after {
  content: '';
  display: block;
  width: 50px;
  height: 3px;
  background-color: #ff6b6b; /* You might want to adjust this color as well */
  margin: 1rem auto 0;
}

.tracks-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 2rem;
  padding: 1rem;
}

.track-info-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.artist-info {
  display: flex;
  align-items: center;
}

.artist-avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
}

.artist-name {
  font-weight: bold;
  font-size: 1.1em;
}


.jamz-display-wrapper {
  flex-shrink: 0;
}

.track-jamz-display {
  display: inline-flex;
  align-items: center;
  background: linear-gradient(135deg, #fd79a8, #fab1a0);
  color: white;
  padding: 8px 12px;
  border-radius: 20px;
  font-weight: bold;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 15px;
}

.track-jamz-display .jamz-star {
  margin-right: 6px;
  font-size: 1.2em;
  animation: spin 4s linear infinite;
}

.track-jamz-display .jamz-text {
  font-size: 1.1em;
  font-weight: 600;
}

.daily-watch-count {
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  color: var(--text-color);
  margin-bottom: 1rem;
}

.watch-trophy {
  margin-right: 0.5rem;
  color: #ffd700;
}

.track-actions {
.track-list {
  list-style: none;
  padding: 0;
}

.track-item {
.track-list {
  list-style: none;
  padding: 0;
}

.track-item {
  margin-bottom: 1rem;
}
}
}

.comments-list {
  margin-top: 2rem;
}

.comment {
  background-color: var(--card-bg-color);
  padding: 1rem;
  border-radius: 8px;
  margin-bottom: 1rem;
}

.comment p {
  margin-bottom: 0.5rem;
}

.comment small {
  color: var(--text-color);
  opacity: 0.7;
}

@media (max-width: 768px) {
  .track-page {
    padding: 15px;
  }

  .track-actions {
    flex-direction: column;
  }

  .track-actions .btn {
    margin-right: 0;
    margin-bottom: 0.5rem;
  }

  .track-actions .btn:last-child {
    margin-bottom: 0;
  }
}

.track-item {
  background: var(--card-bg-color);
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.track-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15);
}

.track-item a {
  text-decoration: none;
  color: inherit;
}

.track-item:hover h3 {
  color: #ff6b6b; /* Or any color that fits your theme */
}

.track-image {
  position: relative;
  height: 200px;
  overflow: hidden;
}

.track-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.track-item img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  transition: all 0.3s ease;
}

.track-item:hover img {
  transform: scale(1.05);
}

.track-item h3 {
  padding: 1rem;
  font-size: 1.2rem;
  color: #333333; /* Dark gray color for better contrast */
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 600; /* Make the text slightly bolder */
}

/* Add a play button overlay on hover */
.track-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.track-item:hover .track-overlay {
  opacity: 1;
}

.track-item .play-button {
  width: 60px;
  height: 60px;
  background-color: rgba(255, 107, 107, 0.8);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease;
}

.track-info h3 {
  margin: 0 0 0.5rem;
  font-size: 1.2rem;
  color: var(--text-color);
}



.play-button {
  width: 60px;
  height: 60px;
  background: var(--primary-color);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 1.5rem;
}


.track-item .play-button svg {
  width: 30px;
  height: 30px;
  fill: #ffffff;
}

.track-item:hover .play-button {
  transform: scale(1.1);
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .latest-tracks-title {
    font-size: 2rem;
  }

  .tracks-grid {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
}

@media (max-width: 480px) {
  .tracks-grid {
    grid-template-columns: 1fr;
  }
}

/* Utility Classes */
.text-center {
  text-align: center;
}

.mt-20 {
  margin-top: 20px;
}

.mb-20 {
  margin-bottom: 20px;
}

/* Responsive Design */
@media (max-width: 768px) {
  body {
    font-size: 14px;
    line-height: 1.4;
  }

  .global-header {
    padding: 10px 15px;
  }

  .btn {
    min-height: 44px;
  }

  .side-menu {
    width: 250px;
  }

  .btn, .btn-platform, .btn-facebook, .btn-twitter, .btn-copy {
    min-height: 44px;
    padding: 12px 15px;
  }
  .platform-buttons {
    flex-direction: column;
    gap: 10px;
  }
  .share-link-container {
    flex-direction: column;
  }
  .share-link-container input {
    border-radius: 6px;
    margin-bottom: 10px;
  }
  .btn-copy {
    border-radius: 6px;
  }
  .xp-display {
    font-size: 0.8em;
    padding: 5px 8px;
  }
  .challenge-2-earn, .share-n-earn {
    padding: 15px;
    margin: 15px 0;
  }
}

@media (max-width: 480px) {
  .form-container,
  .comments-section,
  .track-page,
  .challenge-2-earn,
  .share-n-earn {
    width: 100%;
    padding: 15px;
  }

  .btn,
  .btn-platform,
  .btn-facebook,
  .btn-twitter,
  .btn-copy {
    padding: 10px 12px;
    font-size: 13px;
    min-height: 44px;
  }

  .social-share-buttons {
    flex-direction: column;
  }
  .social-share-buttons .btn-facebook,
  .social-share-buttons .btn-twitter {
    width: 100%;
    margin-bottom: 10px;
  }
  h2 { font-size: 1.5em; }
  .xp-display {
    font-size: 0.7em;
    padding: 4px 6px;
    margin-right: 5px;
  }
}


.form-container {
  max-width: 600px;
  margin: 40px auto;
  padding: 30px;
  background-color: var(--card-bg-color);
  border-radius: 12px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.form-container h2 {
  color: var(--primary-color);
  margin-bottom: 20px;
  text-align: center;
}

.form-group {
  margin-bottom: 25px;
}

.form-group label {
  display: block;
  margin-bottom: 8px;
  font-weight: 600;
  color: var(--text-color);
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 16px;
  line-height: 1.5;
  transition: border-color 0.3s ease;
}

.form-group input:focus,
.form-group textarea:focus {
  border-color: var(--primary-color);
  outline: none;
}

.challenge-group {
.challenge-info {
  font-size: 1rem;
  margin: 0.5rem 0;
}

.challenge-item {
  padding: 1rem;
}
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.challenge-group label {
  flex: 1;
  margin-bottom: 0;
  margin-right: 10px;
}

.challenge-group input {
  flex: 2;
  min-width: 0;
}

.btn {
  display: inline-block;
  padding: 10px 20px;
  background-color: #6c5ce7;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  min-height: 44px;
  margin-right: 10px; /* Adds space to the right of the button */
}


.btn:hover {
  background-color: #5f4dd0;
}

#googleSignIn,
#googleSignUp {
  width: 100%;
  margin-top: 20px;
}

.comments-section {
  max-width: 600px;
  margin: 40px auto;
}

.comment-form textarea {
  width: 100%;
  height: 100px;
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  resize: vertical;
}

.track-page {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  width: 100%;
}

.track-page img {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .form-container,
  .comments-section,
  .track-page {
    width: 100%;
    padding: 15px;
  }
}

.auth-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: var(--card-bg-color);
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 95%;
  position: relative;
  max-height: 90vh;
  overflow-y: auto;
  margin: auto;
}

.modal-content h2 {
  margin-top: 0;
  color: #333;
}


the .auth-popup p {
  color: #666;
  margin-bottom: 1.5rem;
  font-size: 0.9em;
  line-height: 1.4;
}

the .close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #999;
}

the .auth-buttons {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

the .auth-buttons .btn {
  flex: 1;
  margin: 0 0.5rem;
}

the .btn-primary {
  background-color: #6c5ce7;
  color: white;
}

the .btn-secondary {
  background-color: #a29bfe;
  color: white;
}

/* Popup styles */
.xp-reward-popup-overlay,
.auth-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.xp-reward-popup,
.auth-popup {
  background-color: white;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 90%;
  position: relative;
  text-align: center;
}

the .close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #999;
}

the .trophy-icon {
  font-size: 3rem;
  color: gold;
  margin-bottom: 1rem;
}

.xp-reward-popup h2,
.auth-popup h2 {
  margin-top: 0;
  color: #333;
  font-size: 1.5em;
}

.xp-reward-popup p,
.auth-popup p {
  color: #666;
  margin-bottom: 1.5rem;
  font-size: 0.9em;
}

.xp-amount {
  font-weight: bold;
  color: #6c5ce7;
}

the .btn {
  display: inline-block;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 5px;
  background-color: #6c5ce7;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

the .btn:hover {
  background-color: #5b4bc4;
}

/* Ensure the popup is on top of other elements */
#root {
  position: relative;
  z-index: 1;
}

body {
  overflow-x: hidden;
}

/* XP and Like Button Container */
.xp-like-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  margin-bottom: 15px;
  flex-wrap: wrap;
}

/* XP Display Styles */
.xp-display {
  display: flex;
  align-items: center;
  background: linear-gradient(135deg, #6c5ce7, #a29bfe);
  color: white;
  padding: 8px 12px;
  border-radius: 20px;
  font-weight: bold;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.xp-display:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.xp-star {
  margin-right: 6px;
  font-size: 1.2em;
  animation: pulse 1.5s infinite;
}

.track-xp {
  display: flex;
  align-items: center;
  color: var(--primary-color);
}

.xp-text {
  font-size: 1.1em;
  font-weight: 600;
}

@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.1); }
  100% { transform: scale(1); }
}

/* Track XP Display Styles */
.track-xp-display {
  display: inline-flex;
  align-items: center;
  background: linear-gradient(135deg, #fd79a8, #fab1a0);
  color: white;
  padding: 8px 12px;
  border-radius: 20px;
  font-weight: bold;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 15px;
}

.xp-star {
  margin-right: 0.5rem;
}

@media (max-width: 768px) {
  .tracks-grid {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
}

@media (max-width: 480px) {
  .track-listing-page {
    padding: 1rem;
  }
  
  .page-title {
    font-size: 2rem;
  }
  
  .tracks-grid {
    grid-template-columns: 1fr;
  }
}

.track-xp-display .xp-star {
  color: #ffeaa7;
  margin-right: 6px;
  font-size: 1.2em;
  animation: spin 4s linear infinite;
}

@keyframes spin {
  100% { transform: rotate(360deg); }
}

.track-xp-display .xp-text {
  font-size: 1.1em;
  font-weight: 600;
}

@media (max-width: 768px) {
  .xp-display {
    padding: 6px 10px;
    font-size: 0.9em;
  }
  .xp-star, .xp-text { 
    font-size: 1em; 
  }
  .track-xp-display {
    padding: 6px 10px;
    font-size: 0.9em;
  }
}

@media (max-width: 480px) {
  .xp-like-container {
    flex-direction: column;
    align-items: flex-start;
  }
  .xp-display, .track-xp-display {
    width: 100%;
    justify-content: center;
    margin-bottom: 10px;
  }
}

/* Challenge2Earn Styles */
.challenge-2-earn {
  background-color: var(--card-bg-color);
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
}

.challenge-2-earn h2 {
  color: var(--primary-color);
  margin-bottom: 15px;
}

.platform-buttons {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.btn-platform {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 15px;
  border: 2px solid var(--primary-color);
  background-color: transparent;
  color: var(--primary-color);
  border-radius: 8px;
  font-weight: bold;
  transition: all 0.3s ease;
}

.btn-platform:hover,
.btn-platform.selected {
  background-color: var(--primary-color);
  color: white;
}

.btn-platform svg {
  margin-right: 8px;
}

.challenge-submission input {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ddd;
  border-radius: 6px;
}

.close-btn {
  margin-top: 20px;
}

/* Challenge Submission Feedback Popup Styles */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content.challenge-submission-feedback {
  background-color: white;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 90%;
  position: relative;
  text-align: center;
}

.challenge-submission-feedback .success-icon {
  font-size: 3rem;
  color: var(--success-color);
  margin-bottom: 1rem;
}

.challenge-submission-feedback .platform-icon {
  font-size: 2rem;
  color: var(--primary-color);
  margin-bottom: 1rem;
}

.challenge-submission-feedback h2 {
  color: var(--primary-color);
  margin-bottom: 1rem;
  font-size: 1.5em;
}

.challenge-submission-feedback p {
  color: #666;
  margin-bottom: 1rem;
}

body {
  overflow-x: hidden;
}

/* ShareNEarn Styles */
.share-n-earn {
  background-color: var(--card-bg-color);
  border-radius: 12px;
  padding: 25px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
}

.share-n-earn h2 {
  color: var(--primary-color);
  margin-bottom: 15px;
  text-align: center;
  font-size: 1.6em;
}

.share-n-earn p {
  text-align: center;
  margin-bottom: 20px;
  font-size: 0.9em;
  line-height: 1.4;
  color: var(--text-color);
}

.share-link-container {
  display: flex;
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.share-link-container input {
  flex-grow: 1;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 6px 0 0 6px;
  font-size: 14px;
  min-height: 44px;
}

.btn-copy {
  background-color: var(--secondary-color);
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 0 6px 6px 0;
  cursor: pointer;
  transition: background-color 0.3s ease;
  min-height: 44px;
}

.btn-copy:hover {
  background-color: #8f84fe;
}

.social-share-buttons {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-top: 20px;
  flex-wrap: wrap;
}

.btn-facebook,
.btn-twitter {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  border: none;
  border-radius: 6px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  min-height: 44px;
}

.btn-facebook {
  background-color: #3b5998;
  color: white;
}

.btn-twitter {
  background-color: #1da1f2;
  color: white;
}

.btn-facebook:hover,
.btn-twitter:hover {
  opacity: 0.9;
}

@media (max-width: 480px) {
  .social-share-buttons {
    flex-direction: column;
    gap: 10px;
  }
}


/* ... (previous styles remain the same) ... */

/* Dynamic Banner Styles */
.dynamic-banner {
  width: 100%;
  background: linear-gradient(135deg, #4a0e8f, #1e0645);
  border-radius: 30px;
  padding: 4rem 3rem;
  box-sizing: border-box;
  margin-bottom: 4rem;
  overflow: hidden;
  position: relative;
}

.banner-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 2;
}

.banner-left {
  flex: 1;
  max-width: 60%;
}

.banner-left h1 {
  font-size: 3.5rem;
  font-weight: 700;
  margin-bottom: 1rem;
  color: #ffffff;
  line-height: 1.2;
}

.banner-left p {
  font-size: 1.2rem;
  margin-bottom: 2rem;
  color: rgba(255, 255, 255, 0.8);
}

.highlight {
  color: #ff6b6b;
  text-shadow: 0 0 10px rgba(255, 107, 107, 0.5);
}

.fun {
  color: #4ecdc4;
  text-shadow: 0 0 10px rgba(78, 205, 196, 0.5);
}

.feature-showcase {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 15px;
  padding: 1rem;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  height: 60px; /* Set a fixed height */
  overflow: hidden; /* Hide overflow */
}

.feature-content {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
}

.feature-content svg {
  font-size: 2rem;
  margin-right: 1rem;
  color: #4ecdc4;
  flex-shrink: 0;
}

.feature-content span {
  font-size: 1.2rem;
  color: #ffffff;
  white-space: nowrap; /* Prevent text wrapping */
}

.cta-button {
  background-color: #ff6b6b;
  color: #ffffff;
  border: none;
  padding: 1rem 2rem;
  font-size: 1.2rem;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.3s ease;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1px;
}

.cta-button:hover {
  background-color: #ff8787;
  transform: translateY(-3px);
}

.banner-right {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.animated-vinyl {
  width: 300px;
  height: 300px;
  background: url('https://i.imgur.com/NVloZty.png') no-repeat center center;
  background-size: cover;
  border-radius: 50%;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.3);
  animation: spin 10s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.floating-notes {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.note {
  position: absolute;
  font-size: 2rem;
  color: rgba(255, 255, 255, 0.3);
  animation: float 5s ease-in-out infinite;
}


.note-1 { top: 10%; left: 10%; animation-delay: 10s; }
.note-2 { top: 30%; right: 20%; animation-delay: 10s; }
.note-3 { bottom: 15%; left: 30%; animation-delay: 10s; }
.note-4 { top: 40%; right: 10%; animation-delay: 10s; }

/* Responsive adjustments */
@media (max-width: 1024px) {
  .banner-content {
    flex-direction: column;
    text-align: center;
  }

  .banner-left {
    max-width: 100%;
    margin-bottom: 2rem;
  }

  .banner-left h1 {
    font-size: 3rem;
  }

  .feature-showcase {
    justify-content: center;
  }

  .animated-vinyl {
    width: 250px;
    height: 250px;
  }
}

  .banner-left h1 {
    font-size: 2.5rem;
  }

  .banner-left p {
    font-size: 1rem;
  }


/* ... (rest of the styles remain the same) ... */

.animated-vinyl {
  width: 300px;
  height: 300px;
  background: url('https://i.imgur.com/NVloZty.png') no-repeat center center;
  background-size: cover;
  border-radius: 50%;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.3);
  animation: spin 10s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.floating-notes {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.note {
  position: absolute;
  font-size: 2rem;
  color: rgba(255, 255, 255, 0.3);
  animation: float 5s ease-in-out infinite;
}

/* Add multiple note classes with different positions and animation delays */
.note:nth-child(1) { top: 10%; left: 10%; animation-delay: 0s; }
.note:nth-child(2) { top: 30%; right: 20%; animation-delay: 1s; }
.note:nth-child(3) { bottom: 15%; left: 30%; animation-delay: 2s; }
.note:nth-child(4) { top: 40%; right: 10%; animation-delay: 3s; }

/* Responsive adjustments */
@media (max-width: 1024px) {
  .banner-content {
    flex-direction: column;
    text-align: center;
  }

  .banner-left {
    max-width: 100%;
    margin-bottom: 2rem;
  }

  .banner-left h1 {
    font-size: 3rem;
  }

  .feature-showcase {
    justify-content: center;
  }

  .animated-vinyl {
    width: 250px;
    height: 250px;
  }
}

@media (max-width: 768px) {
  .dynamic-banner {
    padding: 3rem 1rem;
    border-radius: 20px;
  }

  .banner-left h1 {
    font-size: 2.5rem;
  }

  .banner-left p {
    font-size: 1rem;
  }

  .animated-vinyl {
    width: 200px;
    height: 200px;
  }
}

.presale-section {
  background: linear-gradient(135deg, #4a0e8f, #1e0645);
  width: calc(100% - 2rem);
  max-width: 1200px;
  margin: 0 auto;
  border-radius: 20px;
  padding: 3rem 1rem;
  color: #ffffff;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  box-sizing: border-box;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

.presale-content {
  text-align: center;
  max-width: 1200px;
  margin: 0 auto;
}

.presale-content h2 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  color: #ff6b6b;
}

.presale-content > p {
  font-size: 1.2rem;
  margin-bottom: 2rem;
  max-width: 800px;
  margin: 0 auto 2rem;
  padding: 0 15px;
}

.presale-features {
  display: flex;
  justify-content: space-around;
  margin-bottom: 3rem;
  flex-wrap: wrap;
}

.presale-feature {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 15px;
  padding: 1.5rem;
  transition: all 0.3s ease;
  width: calc(33.33% - 2rem);
  min-width: 250px;
  max-width: 350px;
  margin-bottom: 1.5rem;
}

.presale-feature:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

.presale-feature svg {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  color: #4ecdc4;
}

.presale-feature h3 {
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
}

.presale-feature p {
  font-size: 0.9rem;
  color: rgba(255, 255, 255, 0.8);
}

.join-presale-btn {
  background-color: #ff6b6b;
  color: #ffffff;
  border: none;
  padding: 1rem 2rem;
  font-size: 1.2rem;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.3s ease;
}

/* Presale Details Styles */
.presale-details {
  margin-top: 2rem;
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 15px;
  padding: 2rem;
  overflow: hidden;
}

.presale-details-content {
  max-width: 600px;
  margin: 0 auto;
}

.presale-details h3 {
  font-size: 1.8rem;
  margin-bottom: 1rem;
  color: #4ecdc4;
}

.participation-steps {
  text-align: left;
  padding-left: 20px;
  margin-bottom: 2rem;
}

.participation-steps li {
  margin-bottom: 0.5rem;
  color: #f0f0f0;
}

.presale-address-section {
  margin-bottom: 2rem;
}

.presale-address-section h4 {
  font-size: 1.2rem;
  margin-bottom: 1rem;
  color: #ff6b6b;
}

.presale-address {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
}

.presale-address input {
  flex-grow: 1;
  padding: 0.75rem;
  border: none;
  border-radius: 5px 0 0 5px;
  font-size: 1rem;
  background-color: rgba(255, 255, 255, 0.1);
  color: #ffffff;
}

.copy-button {
  background-color: #4834d4;
  color: #ffffff;
  border: none;
  padding: 0.75rem 1rem;
  font-size: 1rem;
  border-radius: 0 5px 5px 0;
  cursor: pointer;
  transition: all 0.3s ease;
}

.copy-notification {
  color: #4ecdc4;
  font-size: 0.9rem;
  margin-top: 0.5rem;
}

.jamz-calculator {
  text-align: center;
}

.jamz-calculator h4 {
  font-size: 1.2rem;
  margin-bottom: 1rem;
  color: #ff6b6b;
}

.trx-input {
  width: 100%;
  max-width: 300px;
  padding: 0.75rem;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  margin-bottom: 1rem;
  background-color: rgba(255, 255, 255, 0.1);
  color: #ffffff;
}

.calculator-result {
  font-size: 1.1rem;
}

.calculator-result .highlight {
  color: #ff6b6b;
  font-weight: bold;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .presale-section {
    padding: 2rem 1rem;
    width: calc(100% - 1rem);
  }

  .presale-features {
    flex-direction: column;
    align-items: center;
  }

  .presale-feature {
    width: calc(100% - 2rem);
    max-width: 100%;
    margin-bottom: 1.5rem;
  }

  .presale-details {
    padding: 1.5rem 0.5rem;
  }

  .presale-details h3 {
    font-size: 1.5rem;
  }

  .presale-address, .trx-input {
    width: 100%;
    max-width: 100%;
  }

  .presale-content > p {
    padding: 0;
  }
}

@media (max-width: 480px) {
  .presale-section { border-radius: 10px; width: 100%; }
}

/* ... (existing styles) ... */

/* Updated Footer Styles */
.footer {
  width: 100%;
  background: linear-gradient(135deg, #4a0e8f, #1e0645);
  color: #ffffff;
  padding: 40px 20px 20px;
  font-family: 'Poppins', sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  margin: 40px auto 0;
  border-radius: 30px 30px 0 0;
  max-width: 1200px;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
}

.footer-section {
  flex: 1;
  margin-right: 20px;
  max-width: 300px;
}

.footer-section:nth-child(3) {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.footer-section:nth-child(3) .social-icons {
  margin-top: 10px;
  display: flex;
  justify-content: flex-start;
  width: 100%;
}

@media (min-width: 769px) {
  .footer-section:nth-child(3) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .footer-section:nth-child(3) .social-icons {
    margin-top: 10px;
    justify-content: flex-start;
    width: 100%;
  }
}

@media (max-width: 768px) {
  .footer-section:nth-child(3) {
    align-items: center;
  }

  .footer-section:nth-child(3) .social-icons {
    justify-content: center;
  }
}

.footer-section:last-child {
  margin-right: 0;
}

.footer-section h3 {
  font-size: 1.1rem;
  margin-bottom: 10px;
  color: #ff6b6b;
}

.footer-section p {
  font-size: 0.85rem;
  line-height: 1.4;
  color: rgba(255, 255, 255, 0.8);
}

.footer-section ul {
  list-style-type: none;
  padding: 0;
}

.footer-section ul li {
  margin-bottom: 5px;
}

.footer-section ul li a {
  color: rgba(255, 255, 255, 0.8);
  text-decoration: none;
  transition: color 0.3s ease;
  font-size: 0.85rem;
}

.footer-section ul li a:hover {
  color: #ff6b6b;
}

.social-icons {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.social-icons a {
  color: rgba(255, 255, 255, 0.8);
  font-size: 1.2rem;
  transition: color 0.3s ease;
}

.social-icons a:hover {
  color: #ff6b6b;
}

.footer-bottom {
  text-align: center;
  margin-top: 15px;
  padding-top: 15px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  width: 100%;
}

.footer-bottom p {
  font-size: 0.75rem;
  color: rgba(255, 255, 255, 0.6);
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .footer-content {
    flex-direction: column;
    align-items: center;
  }

  .footer {
    padding: 30px 15px 15px;
  }

  .footer-section {
    margin-bottom: 20px;
    text-align: center;
    width: 100%;
    max-width: none;
    margin-right: 0;
  }

  .social-icons {
    justify-content: center;
  }

  .footer-section:nth-child(3) {
    align-items: center;
  }

  .footer-section:nth-child(3) .social-icons {
    justify-content: center;
  }
}


.youtube-player-wrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
  height: 0;
  overflow: hidden;
  max-width: 100%;
  background: #000;
  margin: 0 auto 20px;
}

.youtube-player-wrapper .youtube-player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media (max-width: 768px) {
  .youtube-player-wrapper {
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100% + 40px);
  }
}

@media (max-width: 480px) {
  .modal-content {
    width: 95%;
    padding: 1.5rem;
  }

  .modal-content h2 {
    font-size: 1.2rem;
  }

  .presale-address,
  .jamz-calculator input,
  .btn-copy,
  .btn-platform,
  .btn-facebook,
  .btn-twitter {
    width: 100%;
    margin-bottom: 10px;
  }
}

.comment-user-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}

.comment-user-info {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: inherit;
}

.comment-username {
  margin-left: 10px;
  font-weight: bold;
}

.comment {
  margin-bottom: 15px;
  padding: 10px;
  border-radius: 8px;
  background-color: #f5f5f5;
}

.comment-content {
  margin-top: 10px;
}

/* Presale Chain Selector Dropdown Styles */
.presale-details .select-wrapper {
  position: relative;
  width: 200px; /* Adjust this value to your preferred width */
  margin: 0 auto 15px;
}

.presale-details select {
  width: 100%;
  padding: 12px 30px 12px 12px; /* Increased right padding for the arrow */
  background-color: rgba(255, 255, 255, 0.1);
  color: #ffffff;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 5px;
  font-size: 1rem;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  cursor: pointer;
  transition: all 0.3s ease;
}

.presale-details select:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.presale-details select:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(78, 205, 196, 0.5);
}

/* Custom dropdown arrow */
.presale-details .select-wrapper::after {
  content: '\25BC';
  position: absolute;
  top: 50%;
  right: 12px;
  transform: translateY(-50%);
  color: #ffffff;
  pointer-events: none;
  font-size: 0.8rem;
}

/* Style for dropdown options */
.presale-details select option {
  background-color: #1e0645;
  color: #ffffff;
}

/* Calculator Styles */
.jamz-calculator {
  margin-top: 30px;
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 10px;
  padding: 20px;
}

.jamz-calculator h4 {
  color: #4ecdc4;
  margin-bottom: 15px;
}

.jamz-calculator input {
  width: 100%;
  max-width: 300px;
  padding: 12px;
  margin-bottom: 15px;
  background-color: rgba(255, 255, 255, 0.1);
  color: #ffffff;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 5px;
  font-size: 1rem;
  transition: all 0.3s ease;
}

.jamz-calculator input:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(78, 205, 196, 0.5);
}

.jamz-calculator p {
  font-size: 1.1rem;
  color: #ff6b6b;
  margin-top: 10px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .presale-details .select-wrapper {
    width: 100%;
  }

  .presale-details select,
  .jamz-calculator input {
    font-size: 0.9rem;
    padding: 10px;
  }

  .jamz-calculator {
    padding: 15px;
  }
}

/* Marketing Ecosystem Fund Styles */
.marketing-ecosystem-fund {
  background: linear-gradient(135deg, #4a0e8f, #1e0645);
  border-radius: 30px;
  padding: 4rem 3rem;
  margin: 4rem auto;
  position: relative;
  overflow: hidden;
  color: #ffffff;
  max-width: 1200px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
}

.fund-content {
  position: relative;
  z-index: 2;
}

.marketing-ecosystem-fund h2 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  color: #ff6b6b;
  text-align: center;
}

.fund-description {
  font-size: 1.2rem;
  margin-bottom: 2rem;
  text-align: center;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.fund-features {
  display: flex;
  justify-content: space-around;
  margin-bottom: 3rem;
  flex-wrap: wrap;
}

.fund-feature {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 15px;
  padding: 1.5rem;
  transition: all 0.3s ease;
  width: calc(33.33% - 2rem);
  min-width: 250px;
  text-align: center;
  margin-bottom: 1rem;
}

.feature-icon {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  color: #4ecdc4;
}

.fund-feature h3 {
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
  color: #ff6b6b;
}

.fund-feature p {
  font-size: 0.9rem;
  color: rgba(255, 255, 255, 0.8);
}

.apply-now-btn {
  background-color: #ff6b6b;
  color: #ffffff;
  border: none;
  padding: 1rem 3rem;
  font-size: 1.2rem;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.3s ease;
  display: inline-block;
  margin: 0 auto;
  width: auto;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 1px;
}

.fund-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
}

.animated-notes {
  position: absolute;
  width: 100%;
  height: 100%;
}

.note {
  position: absolute;
  color: rgba(255, 255, 255, 0.2);
  user-select: none;
}

@media (max-width: 768px) {
  .marketing-ecosystem-fund {
    padding: 3rem 1.5rem;
  }

  .marketing-ecosystem-fund h2 {
    font-size: 2rem;
  }

  .fund-description {
    font-size: 1rem;
  }

  .fund-feature {
    width: 100%;
    margin-bottom: 1.5rem;
  }
}