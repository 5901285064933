.privacy-policy-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 80px 20px;
  color: #333;
  font-family: 'Arial', sans-serif;
  background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%), url('data:image/svg+xml,%3Csvg width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"%3E%3Cg fill="%239C92AC" fill-opacity="0.05" fill-rule="evenodd"%3E%3Ccircle cx="3" cy="3" r="3"/%3E%3Ccircle cx="13" cy="13" r="3"/%3E%3C/g%3E%3C/svg%3E');
  min-height: 100vh;
  position: relative;
}

.privacy-policy-wrapper {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
  padding: 50px;
  position: relative;
  overflow: hidden;
  transition: transform 0.3s ease-in-out;
}

.privacy-policy-wrapper:hover {
  transform: translateY(-5px);
}

.privacy-policy-title {
  color: #6c5ce7;
  font-size: 3.5em;
  margin-bottom: 50px;
  text-align: center;
  font-weight: 800;
  letter-spacing: 2px;
  position: relative;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
}

.privacy-policy-title::after {
  content: '';
  display: block;
  width: 80px;
  height: 5px;
  background: linear-gradient(90deg, #6c5ce7, #a29bfe);
  margin: 25px auto 0;
  border-radius: 3px;
}

.privacy-policy-content {
  line-height: 1.6;
  font-size: 17px;
}

.privacy-policy-content section {
  margin-bottom: 50px;
  padding: 30px;
  background-color: #f8f9fa;
  background-image: url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%239C92AC' fill-opacity='0.1' fill-rule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E");
  border-radius: 12px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
  border-left: 5px solid #6c5ce7;
  position: relative;
  overflow: hidden;
}

.privacy-policy-content section:hover {
  transform: translateY(-8px) scale(1.01);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
}

.privacy-policy-content h2 {
  color: #6c5ce7;
  font-size: 1.8em;
  margin-bottom: 20px;
  border-bottom: 2px solid #6c5ce7;
  padding-bottom: 15px;
  font-weight: 700;
  transition: color 0.3s ease;
}

.privacy-policy-content section:hover h2 {
  color: #4834d4;
}

.privacy-policy-content p {
  margin-bottom: 20px;
  color: #444;
  transition: color 0.3s ease;
}

.privacy-policy-content section:hover p {
  color: #333;
}

.privacy-policy-content ul {
  list-style-type: disc;
  margin-left: 30px;
  margin-bottom: 20px;
  color: #444;
}

.privacy-policy-content li {
  margin-bottom: 10px;
  transition: transform 0.2s ease;
}

.privacy-policy-content li:hover {
  transform: translateX(5px);
}

.privacy-policy-content a {
  color: #6c5ce7;
  text-decoration: none;
  font-weight: 700;
  transition: all 0.3s ease;
  position: relative;
  padding-bottom: 2px;
  display: inline-block;
}

.privacy-policy-content a:hover {
  color: #4834d4;
  transform: translateY(-2px);
}

.privacy-policy-content a::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #6c5ce7;
  transform: scaleX(0);
  transition: transform 0.3s ease;
}

.privacy-policy-content a:hover::after {
  transform: scaleX(1);
}

/* Custom scrollbar */
.privacy-policy-container::-webkit-scrollbar {
  width: 12px;
}

.privacy-policy-container::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.privacy-policy-container::-webkit-scrollbar-thumb {
  background-color: #6c5ce7;
  border-radius: 6px;
  border: 3px solid #f1f1f1;
}

.privacy-policy-container::-webkit-scrollbar-thumb:hover {
  background-color: #4834d4;
}

/* Back to top button */
.back-to-top {
  position: fixed;
  bottom: 30px;
  right: 30px;
  background-color: #6c5ce7;
  color: white;
  border: none;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  font-size: 28px;
  cursor: pointer;
  transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275), background-color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease, color 0.3s ease;
  opacity: 0;
  visibility: hidden;
  box-shadow: 0 4px 10px rgba(108, 92, 231, 0.2);
  z-index: 1000;
}

.back-to-top.visible {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
}

.back-to-top:hover {
  background-color: #4834d4;
  box-shadow: 0 6px 15px rgba(72, 52, 212, 0.3);
  color: #f0f0f0;
  transform: translateY(-3px);
  transition: background-color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease, color 0.3s ease;
}

/* Responsive design improvements */
@media (max-width: 900px) {
  .privacy-policy-container {
    padding: 60px 15px;
  }

  .privacy-policy-wrapper {
    padding: 40px;
  }
}

@media (max-width: 768px) {
  .privacy-policy-container {
    padding: 40px 10px;
  }

  .privacy-policy-title {
    font-size: 2.8em;
  }

  .privacy-policy-content h2 {
    font-size: 1.6em;
  }

  .privacy-policy-content {
    font-size: 16px;
  }
}

@media (max-width: 480px) {
  .privacy-policy-container {
    padding: 30px 10px;
  }

  .privacy-policy-wrapper {
    padding: 25px;
  }

  .privacy-policy-title {
    font-size: 2.5em;
  }
}

@media (max-width: 360px) {
  .privacy-policy-container {
    padding: 20px 5px;
  }

  .privacy-policy-wrapper {
    padding: 10px;
  }

  .privacy-policy-title {
    font-size: 1.6em;
  }

  .privacy-policy-content h2 {
    font-size: 1.1em;
  }

  .privacy-policy-content {
    font-size: 12px;
  }
}

.privacy-policy-content section {
  transition: all 0.3s ease-in-out;
}

.privacy-policy-content section:hover {
  transform: translateY(-5px) scale(1.02);
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
}

.privacy-policy-content section:hover h2 {
  color: #4834d4;
}

.privacy-policy-content h2 {
  transition: color 0.3s ease;
}

.privacy-policy-content section:hover h2 {
  transform: translateX(8px);
}
