.admin-withdrawals-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
}

.admin-withdrawals-container h1 {
  text-align: center;
  color: #333;
  margin-bottom: 20px;
}

.withdrawals-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.withdrawals-table th,
.withdrawals-table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.withdrawals-table th {
  background-color: #f2f2f2;
  font-weight: bold;
  color: #333;
}

.withdrawals-table tr:hover {
  background-color: #f5f5f5;
}

.action-button {
  padding: 8px 12px;
  margin: 0 5px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: all 0.3s ease;
}

.action-button.approve {
  background-color: #4CAF50;
  color: white;
}

.action-button.reject {
  background-color: #f44336;
  color: white;
}

.action-button:hover {
  opacity: 0.8;
}

.error-message {
  color: #f44336;
  text-align: center;
  margin-bottom: 10px;
}

.loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 200px;
}

.spinner {
  animation: spin 1s linear infinite;
  font-size: 24px;
  color: #333;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}