/* Music-oriented Profile Page Styles */
.profile-page {
  max-width: 1000px;
  margin: 2rem auto;
  padding: 2rem;
  background-color: var(--background-color);
  color: var(--text-color);
  border-radius: 15px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
}

/* Profile Header */
.profile-header {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
}

.avatar-container {
  position: relative;
  width: 200px;
  height: 200px;
  margin-right: 2rem;
  cursor: pointer;
}

.avatar {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
  border: 4px solid var(--primary-color);
  box-shadow: 0 0 20px rgba(29, 185, 84, 0.5);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.avatar:hover {
  transform: scale(1.05);
  box-shadow: 0 0 30px rgba(29, 185, 84, 0.7);
}

.avatar-upload-icon {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background-color: var(--primary-color);
  color: white;
  border-radius: 50%;
  padding: 8px;
  font-size: 1.2rem;
  transition: background-color 0.3s ease;
}

.avatar-upload-icon:hover {
  background-color: var(--secondary-color);
}

.profile-info {
  flex-grow: 1;
}

.profile-info h1 {
  font-size: 2.5rem;
  color: var(--primary-color);
  margin-bottom: 1rem;
}

.user-stats {
  display: flex;
  gap: 2rem;
  margin-bottom: 1rem;
}

.stat {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.stat-value {
  font-size: 1.5rem;
  font-weight: bold;
  color: var(--primary-color);
}

.stat-label {
  font-size: 0.9rem;
  color: var(--text-color);
}

.social-actions {
  display: flex;
  gap: 1rem;
}

.social-actions button {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 20px;
  background-color: var(--primary-color);
  color: #fff;
  font-size: 0.9rem;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.social-actions button:hover {
  background-color: var(--secondary-color);
  transform: translateY(-2px);
}

/* Profile Content */
.profile-content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
}

.profile-content section {
  background-color: var(--card-bg-color);
  border-radius: 10px;
  padding: 1.5rem;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.profile-content h2 {
  font-size: 1.5rem;
  color: var(--primary-color);
  margin-bottom: 0;
}

/* About Me Section */
.edit-bio textarea {
  width: 100%;
  min-height: 100px;
  padding: 0.75rem;
  margin-bottom: 1rem;
  border: 1px solid var(--text-color);
  border-radius: 5px;
  background-color: var(--background-color);
  color: var(--text-color);
  resize: vertical;
}

/* Music Section */
.music-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
}

.music-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  background-color: var(--background-color);
  border-radius: 10px;
  transition: all 0.3s ease;
}

.music-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.music-item svg {
  font-size: 2rem;
  color: var(--primary-color);
  margin-bottom: 0.5rem;
}

/* Social Links Section */
.social-icons {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.social-icon {
  font-size: 2rem;
  transition: color 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.social-icon:hover {
  transform: scale(1.1);
}

.edit-social input {
  width: 100%;
  padding: 0.75rem;
  margin-bottom: 1rem;
  border: 1px solid var(--text-color);
  border-radius: 5px;
  background-color: var(--background-color);
  color: var(--text-color);
}

/* Edit Profile Section */
.edit-profile-section input {
  width: 100%;
  padding: 0.75rem;
  margin-bottom: 1rem;
  border: 1px solid var(--text-color);
  border-radius: 5px;
  background-color: var(--background-color);
  color: var(--text-color);
}

/* Common Button Styles */
.btn-edit,
.btn-save,
.btn-cancel {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 0.9rem;
}

.btn-edit,
.btn-save {
  background-color: var(--primary-color);
  color: #fff;
}

.btn-cancel {
  background-color: var(--error-color);
  color: #fff;
}

.btn-edit:hover,
.btn-save:hover,
.btn-cancel:hover {
  opacity: 0.9;
  transform: translateY(-2px);
}

.edit-actions {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 1rem;
}

/* Mobile Responsive Styles */
@media (max-width: 768px) {
  .profile-page {
    padding: 1rem;
  }

  .profile-header {
    flex-direction: column;
    text-align: center;
  }

  .avatar-container {
    margin-right: 0;
    margin-bottom: 1rem;
  }

  .user-stats {
    justify-content: center;
  }

  .profile-content {
    grid-template-columns: 1fr;
  }

  .music-grid {
    grid-template-columns: 1fr;
  }

  .social-actions {
    flex-direction: column;
    align-items: center;
  }

  .social-actions button {
    width: 100%;
  }

  .edit-actions {
    flex-direction: column;
  }

  .btn-edit,
  .btn-save,
  .btn-cancel {
    width: 100%;
  }
}

.social-icon.active {
  opacity: 1;
  color: #ff0000; /* Red color for active icons */
}

.social-icon.inactive {
  opacity: 0.7;
  color: #808080; /* Gray color for inactive icons */
}

.social-links-encouragement {
  font-style: italic;
  color: #1DB954; /* Spotify green as an accent color */
  margin-bottom: 10px;
  text-align: center;
  font-weight: bold;
}