/* JAMZRewardPopup Styles */
.jamz-reward-popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.jamz-reward-popup {
  background-color: white;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 90%;
  position: relative;
  text-align: center;
}

.jamz-reward-popup .close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #999;
}

.jamz-reward-popup .trophy-icon {
  font-size: 3rem;
  color: gold;
  margin-bottom: 1rem;
}

.jamz-reward-popup h2 {
  margin-top: 0;
  color: #333;
  font-size: 1.5em;
}

.jamz-reward-popup .jamz-amount {
  font-size: 2rem;
  font-weight: bold;
  color: #6c5ce7;
  margin: 1rem 0;
}

.jamz-reward-popup p {
  color: #666;
  margin-bottom: 1rem;
}

.jamz-reward-popup .btn {
  display: inline-block;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 5px;
  background-color: #6c5ce7;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.jamz-reward-popup .btn:hover {
  background-color: #5b4bc4;
}

/* JAMZDisplay Styles */
.jamz-display {
  display: flex;
  align-items: center;
  background: linear-gradient(135deg, #6c5ce7, #a29bfe);
  color: white;
  padding: 8px 12px;
  border-radius: 20px;
  font-weight: bold;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  cursor: pointer;
}

.jamz-display:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.jamz-display .jamz-star {
  margin-right: 6px;
  font-size: 1.2em;
  animation: pulse 1.5s infinite;
}

.jamz-display .jamz-text {
  font-size: 1.1em;
}

@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.1); }
  100% { transform: scale(1); }
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .jamz-display {
    padding: 6px 10px;
    font-size: 0.9em;
  }
  .jamz-display .jamz-star,
  .jamz-display .jamz-text { 
    font-size: 1em; 
  }
}