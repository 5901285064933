/* Header Styles */
.global-header {
  background-color: transparent;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
}

@media (min-width: 769px) {
  .global-header {
    position: relative;
    z-index: 1000;
    box-shadow: none;
    border: none;
  }

  .header-content {
    padding: 15px 0;
  }
}

.logo a {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: var(--primary-color);
  font-weight: 700;
  font-size: 1.5rem;
}

.logo img {
  height: 40px;
  margin-right: 10px;
}

nav ul {
  display: flex;
  list-style-type: none;
  align-items: center;
}

nav ul li {
  margin-left: 20px;
}

nav ul li a, nav ul li button {
  text-decoration: none;
  color: var(--text-color);
  font-weight: 500;
  transition: color 0.3s ease;
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
}

nav ul li a:hover, nav ul li button:hover {
  color: var(--primary-color);
}

.profile-link, .wallet-display {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.profile-icon, .wallet-icon {
  margin-right: 8px;
  font-size: 1.2rem;
}

.wallet-display {
  background-color: var(--primary-color);
  color: #ffffff;
  padding: 6px 12px;
  border-radius: 20px;
  transition: background-color 0.3s ease;
}

.wallet-display:hover {
  background-color: var(--secondary-color);
}

.menu-toggle {
  display: none;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: var(--text-color);
}

/* ... (previous styles remain the same) ... */

/* ... (previous styles for desktop remain the same) ... */

/* Circular Menu Styles */
.circular-menu {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: var(--primary-color);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  animation: pulse 2s infinite;
  transform: scale(0.8);
  transform-style: preserve-3d;
}

@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
}

@keyframes shake {
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  100% { transform: translate(0, 0) rotate(0); }
}

.circular-menu.open {
  width: 250px;
  height: auto;
  min-height: 300px;
  transform: scale(1);
  border-radius: 25px;
  background-color: rgba(255, 255, 255, 0.95);
  backdrop-filter: blur(10px);
  animation: none;
  overflow: visible;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 20px;
}

.menu-toggle {
  background: none;
  border: none;
  color: #fff;
  font-size: 24px;
  transition: transform 0.3s ease-in-out;
}

.circular-menu.open .menu-toggle {
  transform: rotate(45deg);
  color: var(--text-color);
}

.menu-items {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
  overflow-y: auto;
  box-sizing: border-box;
  margin-top: 20px;
}

.circular-menu.open .menu-items {
  opacity: 1;
  transform: translateY(0);
}

.menu-items > div {
  position: relative;
  transition: all 0.3s ease-in-out;
  margin-bottom: 15px;
}

.circular-menu.open .menu-items > div {
  transform: none;
  transform: scale(1);
  --i: 0;
}

.menu-items a,
.menu-items button {
  display: flex;
  align-items: center;
  padding: 10px;
  color: var(--text-color);
  text-decoration: none;
  font-size: 16px;
  transition: background-color 0.3s ease;
  width: 100%;
  justify-content: flex-start;
  border-radius: 10px;
  white-space: nowrap;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
  margin-bottom: 10px;
  overflow: hidden;
}

.menu-items a:hover,
.menu-items button:hover {
  background-color: rgba(0, 0, 0, 0.1);
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.menu-items svg {
  margin-right: 10px;
  font-size: 18px;
}

.login-button,
.signup-button,
.logout-button,
.wallet-display {
  background-color: var(--primary-color);
  color: #ffffff;
  border: none;
  padding: 10px 15px;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.login-button:hover,
.signup-button:hover,
.logout-button:hover,
.wallet-display:hover {
  background-color: var(--secondary-color);
}


/* Responsive styles */
@media (min-width: 769px) {
  .circular-menu {
    display: none;
  }
}

@media (max-width: 768px) {
  .desktop-nav {
    display: none;
  }

  .menu-toggle {
    display: block;
  }

  .side-menu {
    width: 100%;
    max-width: 280px;
  }
}


.particle {
  position: absolute;
  width: 8px;
  height: 8px;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 50%;
  pointer-events: none;
  opacity: 0;
  animation: particleAnimation var(--duration) ease-in-out var(--delay) infinite, glowAnimation 2s ease-in-out infinite;
}

@keyframes particleAnimation {
  0% {
    transform: translate(0, 0) scale(0);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: translate(
      calc((var(--x) - 50%) * 200px),
      calc((var(--y) - 50%) * 200px)
    ) scale(1);
    opacity: 0;
  }
}

.ripple {
  position: absolute;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.4);
  transform: scale(0);
  animation: ripple-effect 0.6s linear;
  pointer-events: none;
}

/* ... (previous styles remain the same) ... */

.menu-toggle-button {
  position: fixed;
  top: 20px;
  right: 20px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: var(--primary-color);
  color: #ffffff;
  border: none;
  font-size: 24px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1001;
  transition: all 0.3s ease-in-out;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
}

.menu-toggle-button:hover {
  transform: scale(1.1);
}

.menu-toggle-button.open {
  background-color: transparent;
  color: var(--text-color);
  box-shadow: none;
}

.circular-menu {
  position: fixed;
  top: 20px;
  right: 20px;
  width: 50px;
  height: 50px;
  border-radius: 25px;
  background-color: var(--background-color);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease-in-out;
}

.circular-menu.open {
  width: 250px;
  height: auto;
  max-height: 80vh;
  overflow-y: auto;
  border-radius: 15px;
}

/* ... (rest of the styles remain the same) ... */

/* ... (rest of the styles remain the same) ... */

/* Scrolling Banner Styles */
.scrolling-banner {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 40px; /* Fixed height */
  overflow: hidden;
  background-color: var(--primary-color);
  z-index: 1000; /* Ensure it's above other content */
  will-change: transform;
}

.scrolling-banner-container {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
}

.scrolling-text {
  display: inline-block;
  white-space: nowrap;
  padding-left: 100%;
  animation: scroll-left 30s linear infinite;
  will-change: transform;
}

.scrolling-text span {
  display: inline-block;
  padding: 0 20px;
  color: #ffffff;
  font-weight: 500;
  font-size: 16px;
}

@keyframes scroll-left {
  0% {
    transform: translate3d(0, 0, 0);
  }
  100% {
    transform: translate3d(-100%, 0, 0);
  }
}

/* Adjust content below the banner */
.App {
  padding-top: 40px; /* Same as banner height to prevent content from being covered */
  background-color: var(--background-color);
}

.content-wrapper {
  margin-top: 40px; /* Add margin to prevent content from being covered by the banner */
}

/* Mobile optimizations */
@media (max-width: 768px) {
  .scrolling-banner {
    height: 30px; /* Smaller height on mobile */
  }
  
  .App {
    padding-top: 30px; /* Adjust padding for mobile */
  }
  
  .content-wrapper {
    margin-top: 30px; /* Adjust margin for mobile */
  }
  
  .scrolling-text span {
    font-size: 14px; /* Smaller font size on mobile */
  }
  
  .scrolling-text {
    animation-duration: 20s; /* Faster animation on mobile */
  }
}

/* ... (rest of the styles remain the same) ... */