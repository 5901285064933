.artist-page {
  --artist-primary-color: #1DB954; /* Spotify green */
  --artist-secondary-color: #191414; /* Spotify black */
  --overlay-color-dark: rgba(0, 0, 0, 0.95);
  --overlay-color-medium: rgba(0, 0, 0, 0.85);
  font-family: 'Circular', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

/* Custom scrollbar */
.artist-page ::-webkit-scrollbar {
  width: 10px;
}

.artist-page ::-webkit-scrollbar-track {
  background: var(--artist-secondary-color);
}

.artist-page ::-webkit-scrollbar-thumb {
  background: var(--artist-primary-color);
  border-radius: 5px;
}

.artist-page ::-webkit-scrollbar-thumb:hover {
  background: #1ed760;
}

.artist-page .loading, .artist-page .error {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 1.5rem;
  color: #FFFFFF;
  background-color: var(--artist-secondary-color);
}

.artist-page {
  background-size: cover;
  min-height: 100vh;
  color: #FFFFFF;
}

@media (max-width: 768px) {
  .artist-page {
    padding: 1rem;
  }

  .artist-page .artist-header {
    flex-direction: column;
    align-items: center;
  }

  .artist-page .artist-avatar {
    width: 150px;
    height: 150px;
  }

  .artist-page .artist-stats {
    flex-wrap: wrap;
    justify-content: center;
  }

  .artist-page .stat-item {
    margin: 0.5rem;
  }
}

.artist-page .back-button {
  position: absolute;
  top: 1rem;
  left: 1rem;
  z-index: 10;
}

.artist-page .back-link {
  display: flex;
  align-items: center;
  color: #FFFFFF;
  text-decoration: none;
  font-weight: bold;
  transition: opacity 0.3s ease;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}

.artist-page .back-link:hover {
  opacity: 0.8;
}

.artist-page .back-link span {
  margin-left: 0.5rem;
}

.artist-page .artist-header-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  background-color: rgba(0, 0, 0, 0.7);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.9));
  border-radius: 10px;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px); /* For Safari support */
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.7);
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 1);
  border: 1px solid rgba(255, 255, 255, 0.1); /* Subtle border for better definition */
}

.artist-page .artist-avatar {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  object-fit: cover;
  border: 4px solid var(--artist-primary-color);
  box-shadow: 0 0 20px rgba(29, 185, 84, 0.5);
}

.artist-page .artist-header-content h1 {
  font-size: 2.5rem;
  margin-top: 1rem;
  text-shadow: 3px 3px 8px rgba(0, 0, 0, 1);
}

.artist-page .artist-bio {
  max-width: 600px;
  text-align: center;
  margin: 1rem 0;
  font-style: italic;
  line-height: 1.6;
  text-shadow: 2px 2px 6px rgba(0, 0, 0, 1);
}

.artist-page .social-links {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
}

.artist-page .social-link {
  color: #FFFFFF;
  font-size: 1.5rem;
  transition: color 0.3s ease;
}

.artist-page .social-link:hover, .artist-page .social-link:focus {
  color: var(--artist-primary-color);
}

.artist-page .artist-stats {
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin-top: 2rem;
}

.artist-page .stat-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 0.9rem;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}

.artist-page .stat-item svg {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  color: var(--artist-primary-color);
  filter: drop-shadow(1px 1px 2px rgba(0, 0, 0, 0.5));
}

.artist-page .tracks-container {
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 10px;
  padding: 2rem;
  margin-top: 2rem;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px); /* For Safari support */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  border: 1px solid rgba(255, 255, 255, 0.1); /* Subtle border for better definition */
}

.artist-page .tracks-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1rem;
}

.artist-page .track-item {
  background-color: var(--artist-secondary-color);
  border-radius: 8px;
  padding: 1rem;
  transition: all 0.3s ease;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}

.artist-page .track-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.artist-page .track-item a {
  color: #FFFFFF;
  text-decoration: none;
}