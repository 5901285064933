.wallet-container {
  max-width: 800px;
  margin: 2rem auto;
  padding: 2rem;
  background-color: var(--card-bg-color);
  border-radius: 15px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.wallet-container h1 {
  text-align: center;
  color: var(--primary-color);
  margin-bottom: 2rem;
}

.balance-cards {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  margin-bottom: 2rem;
}

.balance-card {
  flex: 1;
  padding: 1.5rem;
  border-radius: 10px;
  color: white;
  text-align: center;
}

.balance-card h2 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.balance-card .balance {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  font-weight: bold;
}

.balance-card .balance svg {
  margin-right: 0.5rem;
}

.jamz {
  background: linear-gradient(135deg, #6c5ce7, #a29bfe);
}

.usdt {
  background: linear-gradient(135deg, #f39c12, #f1c40f);
}
.trx {
  background: linear-gradient(135deg, #00b894, #55efc4);
}

.wallet-actions {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 2rem;
}

.action-button {
  padding: 0.8rem 1.5rem;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  font-weight: bold;
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: all 0.3s ease;
}

.action-button svg {
  margin-right: 0.5rem;
}

.withdraw {
  background-color: var(--secondary-color);
}

.history {
  background-color: var(--accent-color);
}

.exchange-section,
.withdraw-section,
.confirmation-section {
  background-color: rgba(var(--primary-color-rgb), 0.1);
  padding: 1.5rem;
  border-radius: 10px;
  margin-top: 2rem;
}

.withdraw-section h2 {
  color: var(--primary-color);
  margin-bottom: 1rem;
}

.withdraw-form {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
}

.withdraw-form input {
  flex: 1;
  padding: 0.8rem;
  border: 1px solid var(--secondary-color);
  border-radius: 5px;
  font-size: 1rem;
}

.exchange-form button,
.withdraw-form button {
  padding: 0.8rem 1.5rem;
  font-size: 1rem;
}

.withdraw-form button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.wallet-container.loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 300px;
}

.spinner {
  animation: spin 1s linear infinite;
  font-size: 2rem;
  margin-bottom: 1rem;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.error-message {
  color: var(--error-color);
  background-color: rgba(var(--error-color-rgb), 0.1);
  padding: 1rem;
  border-radius: 5px;
  margin-bottom: 1rem;
  text-align: center;
}

@media (max-width: 768px) {
  .balance-cards {
    flex-direction: column;
  }

  .wallet-actions {
    flex-direction: column;
  }

  .action-button {
    width: 100%;
    justify-content: center;
  }

  .withdraw-form {
    flex-direction: column;
  }

  .withdraw-form select,
  .withdraw-form input,
  .withdraw-form button {
    width: 100%;
  }
}

.exchange-confirmation {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 2rem;
  border-radius: 10px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  text-align: center;
  z-index: 1000;
}

.exchange-confirmation .success-icon {
  font-size: 3rem;
  color: var(--success-color);
  margin-bottom: 1rem;
}

.exchange-confirmation h2 {
  color: var(--primary-color);
  margin-bottom: 1rem;
}

.exchange-confirmation p {
  margin-bottom: 1.5rem;
}

.exchange-confirmation button {
  background-color: var(--primary-color);
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.exchange-confirmation button:hover {
  background-color: var(--secondary-color);
}

.confirmation-section {
  margin-top: 1rem;
  text-align: center;
}

/* ... (previous CSS remains the same) */

.fancy-confirmation-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.fancy-confirmation-popup {
  background-color: var(--card-bg-color);
  padding: 2rem;
  border-radius: 15px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  max-width: 400px;
  width: 90%;
  text-align: center;
  position: relative;
}

.fancy-confirmation-popup .close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.5rem;
  color: var(--text-color);
  cursor: pointer;
}

.fancy-confirmation-popup .success-icon-wrapper {
  width: 80px;
  height: 80px;
  margin: 0 auto 1rem;
  background-color: var(--success-color);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fancy-confirmation-popup .success-icon {
  font-size: 3rem;
  color: white;
}

.fancy-confirmation-popup h2 {
  color: var(--primary-color);
  margin-bottom: 1rem;
}

.fancy-confirmation-popup p {
  margin-bottom: 1.5rem;
  color: var(--text-color);
}

.fancy-confirmation-popup .close-confirmation-button {
  background-color: var(--primary-color);
  color: white;
  border: none;
  padding: 0.8rem 1.5rem;
  border-radius: 5px;
  font-size: 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.fancy-confirmation-popup .close-confirmation-button:hover {
  background-color: var(--secondary-color);
}

@media (max-width: 768px) {
  .fancy-confirmation-popup {
    width: 95%;
    padding: 1.5rem;
  }
}

/* ... (rest of the CSS remains the same) */