.nft-section {
  padding: 4rem 2rem;
  background: linear-gradient(135deg, #1e0645 0%, #130634 100%), linear-gradient(45deg, rgba(108, 92, 231, 0.3) 0%, rgba(255, 118, 117, 0.3) 100%);
  margin: 6rem auto;
  box-shadow: 0 15px 40px rgba(0,0,0,0.6);
  overflow: hidden;
  border-radius: 25px;
  width: 100%;
  box-sizing: border-box;
}

.nft-content {
  max-width: 1200px;
  margin: 0 auto;
  box-sizing: border-box;
}

.nft-columns {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nft-video-column,
.nft-description-column {
  flex: 1;
  padding: 0 1rem;
}

.nft-video-container {
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  box-shadow: 0 20px 50px rgba(0,0,0,0.5), 0 0 40px rgba(108, 92, 231, 0.7);
  animation: glow 3s infinite alternate;
  border-radius: 25px;
}

.nft-video {
  width: 100%;
  border-radius: 25px;
  display: block;
}

.nft-description {
  margin: 2rem 0;
  line-height: 1.9;
  font-size: 1.1rem;
  color: #f0f0f0;
  text-shadow: 1px 1px 3px rgba(0,0,0,0.8);
}

.purchase-nft-btn {
  margin-top: 2rem;
  background: linear-gradient(135deg, #ff7675 0%, #ff6b6b 100%), linear-gradient(45deg, #ff7675 0%, #ff6b6b 100%);
  color: #ffffff;
  border: none;
  padding: 16px 32px;
  font-size: 1.1rem;
  border-radius: 25px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  box-shadow: 0 4px 15px rgba(255, 118, 117, 0.7);
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  position: relative;
  overflow: hidden;
  text-shadow: 1px 1px 2px rgba(0,0,0,0.7);
}

.purchase-nft-btn:hover {
  background: linear-gradient(135deg, #ff6b6b 0%, #ff7675 100%), linear-gradient(45deg, #ff6b6b 0%, #ff7675 100%);
  transform: translateY(-3px);
  box-shadow: 0 6px 25px rgba(255, 118, 117, 0.7);
}

#nft-section h2 {
  font-size: 3.2rem;
  margin-bottom: 2.5rem;
  color: #ffffff;
  font-weight: 800;
  text-shadow: 2px 2px 8px rgba(0,0,0,1);
}

@media (max-width: 768px) {
  .nft-section {
    padding: 3rem 1.5rem;
    margin: 4rem 0;
  }
  .nft-columns {
    flex-direction: column;
  }
  .nft-video-column,
  .nft-description-column {
    padding: 1rem 0;
  }
  #nft-section h2 {
    font-size: 2.6rem;
    text-align: center;
  }
  .nft-description {
    font-size: 1rem;
  }
  .nft-video-column,
  .nft-description-column {
    padding: 0;
    margin-bottom: 2rem;
  }
}

@media (max-width: 480px) {
  #nft-section h2 {
    font-size: 2.2rem;
  }
  .nft-description {
    font-size: 0.95rem;
  }
  .purchase-nft-btn {
    padding: 14px 28px;
    font-size: 1rem;
    width: 100%;
  }
}

@keyframes glow {
  from {
    box-shadow: 0 20px 50px rgba(0,0,0,0.5), 0 0 30px rgba(108, 92, 231, 0.7);
  }
  to {
    box-shadow: 0 20px 50px rgba(0,0,0,0.5), 0 0 50px rgba(108, 92, 231, 0.9);
  }
}
